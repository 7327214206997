import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAkDRCUtXvt2cpFKfIEGXfbg9lyjdq_NnE",
    authDomain: "volt-perfect-package.firebaseapp.com",
    projectId: "volt-perfect-package",
    storageBucket: "volt-perfect-package.appspot.com",
    messagingSenderId: "608347321908",
    appId: "1:608347321908:web:a9caa97013abfc653cbc61",
    measurementId: "G-7SJHT6ND0P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)